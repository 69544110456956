<template>
  <h2 id="WhyNotToUse">{{ $t('about.headline') }}</h2>
  <div class="box">
    <p>{{ $t('about.text') }}</p>
  </div>
</template>


<style scoped lang="scss">
.box{
  padding: 1rem;
  background-color: #fff;
  white-space: pre-line;
}
</style>